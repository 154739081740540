<template>
  <div id="app6">
    <contactUsForm />
  </div>
</template>
<style scoped>
#app2{
  min-height:100vh;

}
</style>
<script>
import contactUsForm from '@/components/contactUsForm.vue'
export default {

  props: {
  customerid :{
      type:String,
      default: () => ("")
  },
  titletext  :{
      type:String,
      default: () => ("Contact us")
  },
  heading:{
      type:String,
      default: () => ("heading comes here")
  },
  showtitle  :{
      type:String,
      default: () => ("true")
  },
  showfirstname :{
      type:String,
      default: () => ("true")
  },
  showlastname :{
      type:String,
      default: () => ("true")
  },
  showemailaddress :{
      type:String,
      default: () => ("true")
  },
  showphonenumber :{
      type:String,
      default: () => ("true")
  },
  showlocation :{
      type:String,
      default: () => ("true")
  },
  showpreferredtime :{
      type:String,
      default: () => ("false")
  },
  showsubject :{
      type:String,
      default: () => ("true")
  },
  showmsgtext  :{
      type:String,
      default: () => ("true")
  } , 
  sendbtntext  :{
      type:String,
      default: () => ("send")
  },
  embadedmaps  :{
      type:String,
      default: () => ('<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53452005.28793382!2d-157.65036949154552!3d35.14535913074742!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4b0d03d337cc6ad9%3A0x9968b72aa2438fa5!2sCanada!5e0!3m2!1sen!2sin!4v1581345038106!5m2!1sen!2sin" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen=""></iframe>')
  },
  addresslan:{
      type:String,
      default: () => ("")
  },
  contactnumber:{
      type:String,
      default: () => ("")
  },
  contactemail:{
      type:String,
      default: () => ("")
  },
  showcaptch:{
      type:String,
      default: () => ("false")
  },
  captchasitekey:{
      type:String,
      default: () => ("")
  },
  buttonbgcolor: {
    type: String,
    default: () =>
      ""
  },
  buttontextcolor: {
    type: String,
    default: () =>
      ""
  },    
  fontcolor: {
    type: String,
    default: () =>
      ""
  },    
  logohref: {
    type: String,
    default: () =>
      "#"
  }
  },
  components: {
    contactUsForm
  },
  data () {
    return {
    }
  },
  created () {
    console.log("contactus with appmastery")
  }
}
</script>
