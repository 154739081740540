<template>
   <div class="contactus-widget-container">
         <div id="form" class="">
            <h1 class="title" :style="{ color: fontcolor}">{{heading}}</h1>
            <h2 class="title" :style="{ color: fontcolor}">{{title}}</h2>
            <div class="widget-input-cont" v-if="showFirstName == 'true'">
               <input required="true" class="widget-inputText" :style="{ color: fontcolor}" type="text"  v-bind="firstName">
               <span :style="{ color: fontcolor}" class="widget-floating-label">First name</span>
            </div>
            <div class="widget-input-cont" v-if="showFirstName == 'true'">
               <input required="true" class="widget-inputText" :style="{ color: fontcolor}" type="text"  v-bind="firstName">    
               <span :style="{ color: fontcolor}" class="widget-floating-label">Last Name</span>      
            </div>
            <div class="widget-input-cont" v-if="showEmailAddress == 'true'">
               <input required="true" class="widget-inputText" :style="{ color: fontcolor}" type="text"  v-bind="emailAddress" >
               <span :style="{ color: fontcolor}" class="widget-floating-label">Email</span>
            </div>
            <div class="widget-input-cont" v-if="showPhoneNumber == 'true'">
               <input required="true" class="widget-inputText" :style="{ color: fontcolor}" type="text"  v-bind="phoneNumber" >
               <span :style="{ color: fontcolor}" class="widget-floating-label">Phone Number</span>
            </div>
            <div class="widget-input-cont" v-if="showLocation == 'true'">
               <input required="true" class="widget-inputText" :style="{ color: fontcolor}" type="text"  v-bind="location" >
               <span :style="{ color: fontcolor}" class="widget-floating-label">Company</span>
            </div>
            <div class="widget-input-cont" v-if="showPreferredTime == 'true'">
               <input required="true" class="widget-inputText" :style="{ color: fontcolor}" type="datetime-local" >
               <span :style="{ color: fontcolor}" class="widget-floating-label timeslot">Preferred Timeslot</span>
            </div>
            <div class="widget-input-cont" v-if="showSubject == 'true'">
               <input required="true" class="widget-inputText" :style="{ color: fontcolor}" type="text"  v-bind="subject" >
               <span :style="{ color: fontcolor}" class="widget-floating-label">Subject</span>
            </div>
            <div class="widget-input-cont widthmax" v-if="showSubject == 'true'">
               <textarea  required="true" class="textarea-widget"  rows="3" v-bind="description" :style="{ color: fontcolor}" ></textarea>
               <span class="widget-floating-label textarea" :style="{ color: fontcolor}">Message</span>
            </div>
            <div class="widget-input-cont widthmax">
               <button class="widget-btn-primary" :style="{ color: buttontextcolor, backgroundColor:buttonbgcolor,borderColor:buttonbgcolor }" @click="sendData" :disabled="btndisable">Send</button>
            </div>
            <div class="widget-success-msg" v-if="showerr">{{showresmsg}}</div>
         </div>
   </div>
   </div>
</template>
<style scoped>
#address{
  box-sizing: border-box;
  display: inline-block;
}
#form{
 padding: 5px;
 margin-top: 30px;
}
.widget-input-cont{
    position: relative;
    margin-top: 44px;
    width: 50%;
    display: inline-flex;
}  
.address-cont{
  max-width: 90%;
  padding: 5px;
  margin: 0 auto;
}

.map iframe{
  width: 70%;
}
iframe{
  width: 100%;
}
.icon-cont{
    width: 18%;
    display: inline-block;
    font-size: 40px;
    vertical-align: top;
}
.address-lan{
  margin-top: 10px;
  margin-bottom: 20px;
}
.address-text{
    display: inline-block;
    width: 80%;
}
.address-text .text{
  font-size: 16px;
}
.widget-inputText {
    width: 90%;
    color: #097b8e;
    padding: 5px;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border: none;
    border-bottom: 1px solid;
}
.textarea-widget {
    width: 96%;
    color: #097b8e;
    padding: 5px;
    font-size: 16px;
    border: none;
    border-radius: 2px 0 0 2px;
    border-bottom: 1px solid;
    outline: none !important;
    resize: none;
}

.widget-floating-label {
    position: absolute;
    width: 90%;
    pointer-events: none;
    left: 5px; 
    top: 50%; 
    color: #097b8e; 
    transform: translate(0px, -50%);  
    -webkit-transition: 0.2s ease all;  
    transition: 0.2s ease all;
}
.widget-floating-label.textarea{
  top: 20%; 
}
.contactus-widget-container{
   margin: 0 auto;
    min-width: 50%;
    overflow: hidden;
    max-width: 50%;
    color: #6fafbb;
}


.widget-btn-primary{
    width: 50%;
    background-color:#6fafbb;;
    padding-right: 30px;
    -webkit-transition: all 0.3s ease 0.2s;
    -webkit-transition: all 0.3s ease 0.1s;
    transition: all 0.3s ease 0.1s;
    text-shadow: none;
    border-width: 0;
    text-decoration: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-transform: uppercase;
    letter-spacing: .07em;
    color: #fff;
    position: relative;
    display: inline-block;
    padding: 10px 4px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    margin: 0 auto;
}
.widget-btn-primary:hover{
      background-color: #086273;
      opacity: 0.9;
} 
.title{
  text-align: center;
  color:#086273;
}

.widget-inputText:focus ~ .widget-floating-label,   
.widget-inputText:not(:focus):valid ~ .widget-floating-label{    
    top: -20%;
    color: #6fafbb;
    margin: 0 auto;
    left: 0px;
    font-size: 14px;
    opacity: 1;
    padding-left: 5px;
}   
.textarea-widget:focus ~ .widget-floating-label,    
.textarea-widget:not(:focus):valid ~ .widget-floating-label{    
    top: 20%;
    color: #6fafbb;
    margin: 0 auto;
    font-size: 14px;
    opacity: 1;
 
}
.widget-inputText:focus ~ .widget-floating-label.textarea,   
.widget-inputText:not(:focus):valid ~ .widget-floating-label.textarea{    
    
    padding-left: 5px;
    color: #6fafbb;
}   
.textarea-widget:focus ~ .widget-floating-label.textarea,    
.textarea-widget:not(:focus):valid ~ .widget-floating-label.textarea{    
    top: -6%;
    color: #6fafbb;
}
.widthmax{
  width: 100%;
}
input:focus {
    border-bottom: 2px solid;
}
textarea:focus {
    border-bottom: 2px solid;
}

@media only screen and (max-width: 600px) {
  .contactus-widget-container{
    margin: 0 auto;
    min-width: 100%;
    overflow: hidden;
    max-width: 100%;
  }
  .widget-input-cont{
    width: 100%;
  }
  .widget-inputText {
    width: 96%;
  }

}
</style>

<script>
export default {
  name: 'contactUsForm',
  components: {
  },
  
  beforeCreate () {


  },
  created () {

    var self = this
    console.log ( this.$root.redetail);
    var Data = {}
    this.customerid = this.$root.customerid;
    this.title = this.$root.titletext;
    this.heading = this.$root.heading;
    this.showTitle  = this.$root.showtitle;
    this.showFirstName = this.$root.showfirstname;
    this.showLastName = this.$root.showtastname;
    this.showEmailAddress = this.$root.showemailaddress;
    this.showPhoneNumber = this.$root.showphonenumber;
    this.showLocation = this.$root.showlocation;
    this.showPreferredTime = this.$root.showpreferredtime;
    this.showSubject = this.$root.showsubject;
    this.showMsgText  = this.$root.showmsgtext;
    this.sendbtntext = this.$root.sendbtntext;
    this.embadedMaps = this.$root.embadedmaps;
    this.addressLan = this.$root.addresslan;
    this.contactNumber = this.$root.contactnumber;
    this.contactEmail = this.$root.contactemail;
    this.showCaptch = this.$root.showcaptch;
    this.captchaSitekey = this.captchasitekey;
    this.buttonbgcolor= this.$root.buttonbgcolor;
    this.buttontextcolor= this.$root.buttontextcolor;
    this.fontcolor= this.$root.fontcolor;
    this.logohref= this.$root.logohref;

  },
  mounted(){

  },
  data: () => ({
    showCaptch:false,
    captchaSitekey:null,
    recaptchaMessage :"Please check Recaptcha!",
    submitted:false,
    sendbtntext:'',
    btndisable:false,
    title: '',
    heading:'',
    showresmsg:'',
    showTitle:false,
    showFirstName:false,
    showLastName:false,
    showEmailAddress:false,
    showPhoneNumber:false,
    showLocation:false,
    showPreferredTime:false,
    showSubject:false,
    showMsgText : false,
    title : '',
    firstName : '',
    lastName : '',
    emailAddress : '',
    phoneNumber : '',
    location : '',
    preferredTime : '',
    subject : '',
    msgText : '',
    showerr:false,
    embadedMaps:'',
    addressLan:'',
    contactNumber:'',
    ecaptchaVerified:false,
    description:"",
    

    
  }),
  computed: {
    

  },
  methods: { 
    getHrefEmail(email){
      return 'mailto:'+email
    },
    onCaptchaVerified() {
      this.recaptchaVerified = true;
    },
    onCaptchaExpired() {
    this.recaptchaVerified = false;
    },
    ValidateEmail(mail) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(mail.match(mailformat)){
        
        return true;
    }else{
        alert("You have entered an invalid email address!");
        return false;
    }
    },
    sendData (id) {
      this.submitted = true;
         var DataToSend = {
                phone:this.phoneNumber,
                email:this.emailAddress,
                description:this.description,
                title:this.subject,
                company:this.location
            }
      self = this;
      if(this.ValidateEmail(this.emailAddress)){
      this.$store.dispatch('sendContactUsDetails',DataToSend).then((res)=>{
        slef.showresmsg = res.message;
        self.showerr = true;
         this.phoneNumber = "";
         this.emailAddress= "";
         this.description ="";
         this.subject= "";
          this.location = "";
      });  
      }
      
        
  },
}
}
</script>
