import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import mutations from './mutations'
import axios from 'axios'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  	userData: {},
  	accessToken : '',

  },
  getters,
  mutations,
  actions: {
  	sendContactUsDetails(state,payload){
      var dyHeader = {};
      if(true){
        dyHeader.customerId = payload.customerid
      }else{
        dyHeader.customerId = payload.customerid
      }

      var data = payload;
      delete data.customerid;
  		var self = this;
  		return new Promise((resolve, reject) => {
	         axios.post('https://api.appmastery.co/api/v1/apps/contactus', data,
	           {
        	     headers: dyHeader,

        	}).then((response) => {
	          resolve(response.data);
	        }).catch(() => {
	          reject();
	        });
      	});
  	}
  }
})
